import React from "react";
import "./styles.css";

const NotificationBar = () => {
  return (
    <div className="notification-bar">
      <p> 🚚 ENVIOS A TODO EL PAIS - EXCLUSIVO MAYORISTAS 🏭</p>
    </div>
  );
};

export default NotificationBar;
